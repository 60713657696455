import React from 'react';
import { 
  Container, Typography, Box,
  styled, useTheme
} from '@mui/material';
import { Analytics } from "@vercel/analytics/react"
import AvailabilityChecker from './AvailabilityChecker';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const TitleContainer = styled(Box)({
  display: 'flex',
  alignItems: 'baseline',
});

const LargeDot = styled(Typography)(({ theme }) => ({
  fontSize: '5rem',
  lineHeight: 0,
  color: theme.palette.primary.main,
}));

const MovieGenerator = () => {
    const theme = useTheme();
  
    return (
      <AvailabilityChecker pageId="ascii-movie-generator">
      <StyledContainer maxWidth="md">
        <TitleContainer>
          <Typography variant="h4" gutterBottom sx={{ color: theme.palette.text.primary }}>
            ASCII Movie Generator
          </Typography>
          <LargeDot>.</LargeDot>
        </TitleContainer>
        <Typography 
          variant="body1" 
          sx={{
            color: theme.palette.text.secondary,
            fontStyle: 'italic',
            marginBottom: theme.spacing(2),
          }}
        >
          It started with the thought of a beaver bitting a tree, and the tree falling down.
        </Typography>
        
        <Typography variant="body1" paragraph>
          Instert the GIF above!
          TBD.
        </Typography>
        <Analytics />
      </StyledContainer>
      </AvailabilityChecker>
    );
  };
  
  export default MovieGenerator;