import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { 
  Card, CardContent, Container, Typography, Box, styled
} from '@mui/material';

const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(4),
  background: 'rgba(255, 255, 255, 0.7)',
  backdropFilter: 'blur(15px)',
  border: '1px solid rgba(255, 255, 255, 0.3)',
  borderRadius: '15px',
  boxShadow: theme.shadows[2],
  padding: theme.spacing(2, 3),
}));

const MarkdownContainer = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  lineHeight: 1.7,
  fontSize: '1.05rem',
  
  '& h1, & h2, & h3, & h4, & h5, & h6': {
    fontWeight: 600,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    lineHeight: 1.3,
  },
  '& h1': { ...theme.typography.h4 },
  '& h2': { ...theme.typography.h5 },
  '& h3': {
    ...theme.typography.h6,
    fontSize: '1.2rem',
  },
  '& p': {
    marginBottom: theme.spacing(2),
  },
  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '& code': {
    backgroundColor: theme.palette.grey[200],
    padding: '2px 4px',
    borderRadius: '4px',
    fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
    fontSize: '0.95rem',
  },
  '& pre': {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    borderRadius: '8px',
    overflowX: 'auto',
    marginBottom: theme.spacing(3),
  },
  '& blockquote': {
    borderLeft: `4px solid ${theme.palette.grey[400]}`,
    paddingLeft: theme.spacing(2),
    margin: theme.spacing(2, 0),
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    backgroundColor: theme.palette.grey[50],
    borderRadius: '4px',
  },
  '& ul, & ol': {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
  },
  '& li': {
    marginBottom: theme.spacing(1),
  },
  '& table': {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: theme.spacing(3),
    fontSize: '0.95rem',
  },
  '& th, & td': {
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(1),
    textAlign: 'left',
    verticalAlign: 'top',
  },
  '& th': {
    backgroundColor: theme.palette.grey[200],
    fontWeight: 600,
  },
  '& hr': {
    border: 'none',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    margin: theme.spacing(4, 0),
  },
  '& img': {
    maxWidth: '100%',
    height: 'auto',
    marginBottom: theme.spacing(2),
    borderRadius: '4px',
  },
}));

const BlogPost = ({ posts }) => {
  const { id } = useParams();
  const [markdown, setMarkdown] = useState('');

  const post = posts.find((p) => p.id === id);

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await fetch(`/posts/${id}.md`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        let text = await response.text();
        
        // Remove front matter
        const frontMatterRegex = /^---[\s\S]*?---\s*/;
        text = text.replace(frontMatterRegex, '');
        
        // Optionally remove the first heading if it matches the title
        // This helps avoid duplication if the markdown starts with a # that equals the title
        const firstHeadingRegex = new RegExp(`^#\\s+${post.title}\\s*\n`, 'i');
        text = text.replace(firstHeadingRegex, '');

        setMarkdown(text);
      } catch (error) {
        console.error('Error loading markdown:', error);
      }
    };
    fetchMarkdown();
  }, [id, post?.title]);

  if (!post) {
    return (
      <Container maxWidth="md">
        <Typography variant="h4" sx={{ mt: 4 }}>Post not found</Typography>
      </Container>
    );
  }

  // Format the date nicely
  const formattedDate = new Date(post.date).toLocaleDateString('en-US', {
    year: 'numeric', month: 'long', day: 'numeric'
  });

  return (
    <Container maxWidth="md">
      <StyledCard>
        <CardContent>
          {/* Display the main title and a nicely formatted date */}
          <Typography variant="h3" gutterBottom sx={{ fontWeight: 700 }}>
            {post.title}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            {formattedDate}
          </Typography>

          <MarkdownContainer className="markdown-content">
            <ReactMarkdown
              remarkPlugins={[remarkGfm, remarkMath]}
              rehypePlugins={[rehypeKatex]}
            >
              {markdown}
            </ReactMarkdown>
          </MarkdownContainer>
          
          {/* If you don’t want to show tags or note, comment them out */}
          {/* 
          <Box sx={{ display: 'flex', gap: 1, mt: 4, flexWrap: 'wrap' }}>
            {post.tags.map((tag) => (
              <Chip
                key={tag}
                label={tag}
                size="small"
                variant="outlined"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  borderColor: 'rgba(255, 255, 255, 0.2)',
                  fontWeight: 500,
                }}
              />
            ))}
          </Box>
          */}
        </CardContent>
      </StyledCard>
    </Container>
  );
};

export default BlogPost;
