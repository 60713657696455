import React, { useState } from 'react';
import { 
  Container, Typography, TextField, Button, Paper, Box,
  styled, useTheme, CircularProgress
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
// import DownloadIcon from '@mui/icons-material/Download';
import { Analytics } from "@vercel/analytics/react"

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  background: 'rgba(255, 255, 255, 0.5)',
  backdropFilter: 'blur(15px)',
  border: '2px solid rgba(255, 255, 255, 0.3)',
  borderRadius: '15px',
  boxShadow: 'none',
  height: '300px',  // Reduced height
  overflowY: 'auto',
}));

const MessageBubble = styled(({ isUser, ...props }) => <Box {...props} />)(({ theme, isUser }) => ({
  background: isUser ? theme.palette.primary.main : 'rgba(255, 255, 255, 0.7)',
  color: isUser ? theme.palette.primary.contrastText : theme.palette.text.primary,
  padding: theme.spacing(1, 2),
  borderRadius: '20px',
  maxWidth: '80%',
  marginBottom: theme.spacing(1),
  alignSelf: isUser ? 'flex-end' : 'flex-start',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    backdropFilter: 'blur(15px)',
    borderRadius: '15px',
    '& fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: '2px',
      borderRadius: '15px',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: '2px',
    },
  },
}));

const TitleContainer = styled(Box)({
  display: 'flex',
  alignItems: 'baseline',
});

const LargeDot = styled(Typography)(({ theme }) => ({
  fontSize: '5rem',
  lineHeight: 0,
  color: theme.palette.primary.main,
}));

const ChatbotApp = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();
  
    const handleSend = async () => {
      if (input.trim() === '') return;
  
      const newUserMessage = { role: 'user', content: input };
      const newMessages = [...messages, newUserMessage];
      setMessages(newMessages);
      setInput('');
      setIsLoading(true);
  
      try {
        const response = await fetch('/api/chat', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ messages: newMessages }),
        });
  
        if (!response.ok) {
          throw new Error('API request failed');
        }
  
        const data = await response.json();
        setMessages([...newMessages, { role: 'assistant', content: data.response }]);
      } catch (error) {
        console.error('Error:', error);
        setMessages([...newMessages, { role: 'assistant', content: "I'm sorry, I encountered an error. Please try again later." }]);
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <StyledContainer maxWidth="md">
        <TitleContainer>
          <Typography variant="h4" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Talk to Hendy
          </Typography>
          <LargeDot>.</LargeDot>
        </TitleContainer>
        <Typography 
          variant="body1" 
          sx={{
            color: theme.palette.text.secondary,
            fontStyle: 'italic',
            marginBottom: theme.spacing(2),
          }}
        >
          Master Hendy is a fine-tuned gpt model that aspires to be as e... elegant as Henry Henderson.
        </Typography>
        <StyledPaper>
        <Box display="flex" flexDirection="column">
            {messages.map((message, index) => (
            <MessageBubble key={index} isUser={message.role === 'user'}>
                {message.content}
            </MessageBubble>
            ))}
        </Box>
        </StyledPaper>
        <Box 
            display="flex"
            marginBottom={2}
            sx={{ 
                alignItems: 'stretch',
                height: '56px'  // Set a fixed height for the container
            }}>
          <StyledTextField
            fullWidth
            variant="outlined"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && !isLoading && handleSend()}
            placeholder="Type your message..."
            disabled={isLoading}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSend}
            disabled={isLoading}
            sx={{ 
              ml: 1, 
              borderRadius: '15px',
              minWidth: '48px',
              width: '48px',
              height: '100%',
              padding: 0,
              boxShadow: 'none', // This removes the shadow
                '&:hover': {
                    boxShadow: 'none', // This ensures the shadow doesn't appear on hover
                },
            }}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <SendIcon sx={{ fontSize: 28 }} />
            )}
          </Button>
        </Box>
        <Typography variant="body1" paragraph>
          Master Hendy a fine-tuned gpt-3.5-turbo-0125 model. The data is inspired by the unmatched elegance of Henry Henderson, the epitome of elegance, a character from Spy x Family.
          <br/>
          ELEGANTEEE.
        </Typography>
        <Analytics />
      </StyledContainer>
    );
  };
  
  export default ChatbotApp;