import React, { useState, useEffect } from 'react';
import { 
  Typography, Box,
  styled, CircularProgress
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Analytics } from "@vercel/analytics/react";

const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'calc(100vh - 150px)', // Subtract the height of the AppBar
  padding: theme.spacing(2),
}));

const UnavailableMessage = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  textAlign: 'center',
  marginBottom: theme.spacing(2),
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  cursor: 'pointer',
}));

const AvailabilityChecker = ({ pageId, children }) => {
  const [isAvailable, setIsAvailable] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAvailability = async () => {
      setIsLoading(true);
      try {
        console.log('Fetching availability data...');
        const response = await fetch('/playground.json');
        const data = await response.json();
        console.log('Fetched data:', data);
        const pageData = data.find(item => item.id === pageId);
        console.log('Page data for', pageId, ':', pageData);
        if (pageData) {
          setIsAvailable(pageData.available);
        } else {
          console.error('Page data not found for id:', pageId);
          setIsAvailable(false);
        }
      } catch (error) {
        console.error('Error fetching availability:', error);
        setIsAvailable(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkAvailability();
  }, [pageId]);

  if (isLoading) {
    return (
      <StyledContainer>
        <CircularProgress />
      </StyledContainer>
    );
  }

  if (!isAvailable) {
    return (
      <StyledContainer>
        <UnavailableMessage variant="h4" gutterBottom>
          Cheeky! This page is not available yet, {' '}
          <StyledLink to="/playground">
            go play something else
          </StyledLink>
        </UnavailableMessage>
        <Analytics />
      </StyledContainer>
    );
  }

  return children;
};

export default AvailabilityChecker;