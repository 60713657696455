import React from 'react';
import { 
  Container, Typography, Box,
  styled, useTheme, Paper
} from '@mui/material';
import { Analytics } from "@vercel/analytics/react"
import AvailabilityChecker from './AvailabilityChecker';
import Game from './Game';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const TitleContainer = styled(Box)({
  display: 'flex',
  alignItems: 'baseline',
});

const LargeDot = styled(Typography)(({ theme }) => ({
  fontSize: '5rem',
  lineHeight: 0,
  color: theme.palette.primary.main,
}));

const GameContainer = styled(Paper)(({ theme }) => ({
    width: 'fit-content',
    margin: '0 auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }));


const FightClub = () => {
  const theme = useTheme();

  return (
    <AvailabilityChecker pageId="fightclub">
      <StyledContainer maxWidth="md">
        <TitleContainer>
          <Typography variant="h4" gutterBottom sx={{ color: theme.palette.text.primary }}>
            LLM Fightclub
          </Typography>
          <LargeDot>.</LargeDot>
        </TitleContainer>
        <Typography 
          variant="body1" 
          sx={{
            color: theme.palette.text.secondary,
            fontStyle: 'italic',
            marginBottom: theme.spacing(2),
          }}
        >
          This merges my love for beautiful visualisations, a curiosity for LLM capabilities, and hours wasted in games.
        </Typography>
        
        <Typography variant="body1" paragraph>
          TBD.
        </Typography>

        <GameContainer elevation={3}>
          <Game />
        </GameContainer>

        <Typography variant="body1" paragraph>
          Additional content or explanations can go here, below the game.
        </Typography>

        <Analytics />
      </StyledContainer>
    </AvailabilityChecker>
  );
};

export default FightClub;