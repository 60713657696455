import React, { useState, useEffect, useCallback, useRef } from 'react';
import LLMPlayer from './LLMPlayer';

const GRID_SIZE = 64;
const TEAM_COLORS = ['red', 'blue'];
const SHOT_COLOR = 'yellow';
const OBSTACLE_COLOR = 'brown';
const MAX_HEALTH = 3;
const BULLET_SPEED = 4;
const MAX_MAGAZINE = 5;
const SHOT_REGEN_RATE = 3;

const PLAYER_TYPES = {
  HUMAN: 'human',
  RANDOM: 'random',
  LLM: 'llm'
};

const isObstacle = (x, y, obstacles) => {
  return obstacles.some(obstacle => 
    Math.abs(obstacle.x - x) < 2 && Math.abs(obstacle.y - y) < 2
  );
};

const getRandomPlayerMove = (player, gameState) => {
  const moves = [[-1/2, 0], [1/2, 0], [0, -1/2], [0, 1/2], [0, 0]];

  const [dx, dy] = moves[Math.floor(Math.random() * moves.length)];
  const newX = player.x + dx;
  const newY = player.y + dy;
  
  const shoot = Math.random() < 0.3 && player.magazine > 0;
  let shotDirection = null;

  if (shoot) {
    shotDirection = moves[Math.floor(Math.random() * (moves.length - 1))]; // Exclude [0, 0]
  }

  if (newX >= 0 && newX < GRID_SIZE &&
    newY >= 0 && newY < GRID_SIZE &&
    !isObstacle(Math.round(newX), Math.round(newY), gameState.obstacles)){
    return {
      move: { x: dx, y: dy },
      shoot: shoot ? [Math.sign(shotDirection[0]), Math.sign(shotDirection[1])] : null
    };
  } else {
    return {
      move: { x: 0, y: 0 },
      shoot: null
    };
  }
};

const Game = () => {
  const [gameState, setGameState] = useState(null);
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [pendingMoves, setPendingMoves] = useState({});
  const humanMoveRef = useRef({ dx: 0, dy: 0, shoot: false, lastDirection: { dx: 0, dy: 1 } });
  const llmPlayers = useRef({});

  const initializeGame = () => {
    const obstacles = [];
    for (let i = 0; i < 5; i++) {
      const x = Math.floor(Math.random() * (GRID_SIZE - 2));
      const y = Math.floor(Math.random() * (GRID_SIZE - 2));
      for (let dx = 0; dx < 3; dx++) {
        for (let dy = 0; dy < 3; dy++) {
          obstacles.push({ x: x + dx, y: y + dy });
        }
      }
    }

    return {
      players: [
        { id: 0, x: 0, y: 0, team: 0, health: MAX_HEALTH, type: PLAYER_TYPES.HUMAN, magazine: MAX_MAGAZINE, regenCounter: 0 },
        { id: 1, x: 63, y: 0, team: 1, health: MAX_HEALTH, type: PLAYER_TYPES.RANDOM, magazine: MAX_MAGAZINE, regenCounter: 0 },
        { id: 2, x: 0, y: 63, team: 0, health: MAX_HEALTH, type: PLAYER_TYPES.RANDOM, magazine: MAX_MAGAZINE, regenCounter: 0 },
        { id: 3, x: 63, y: 63, team: 1, health: MAX_HEALTH, type: PLAYER_TYPES.RANDOM, magazine: MAX_MAGAZINE, regenCounter: 0 },
      ],
      shots: [],
      obstacles,
      turn: 0,
    };
  };

  const startGame = () => {
    setGameState(initializeGame());
    setIsGameStarted(true);
    requestNextMoves();
  };

  const handleKeyDown = useCallback((event) => {
    if (!isGameStarted) return;
    switch (event.key.toLowerCase()) {
      case 'w':
        humanMoveRef.current = { ...humanMoveRef.current, dy: -1/2, lastDirection: { dx: 0, dy: -1 } };
        break;
      case 's':
        humanMoveRef.current = { ...humanMoveRef.current, dy: 1/2, lastDirection: { dx: 0, dy: 1 } };
        break;
      case 'a':
        humanMoveRef.current = { ...humanMoveRef.current, dx: -1/2, lastDirection: { dx: -1, dy: 0 } };
        break;
      case 'd':
        humanMoveRef.current = { ...humanMoveRef.current, dx: 1/2, lastDirection: { dx: 1, dy: 0 } };
        break;
      case 'm':
        humanMoveRef.current = { ...humanMoveRef.current, shoot: true };
        break;
      default:
        break;
    }
  }, [isGameStarted]);

  const handleKeyUp = useCallback((event) => {
    if (!isGameStarted) return;
    switch (event.key.toLowerCase()) {
      case 'w':
      case 's':
        humanMoveRef.current = { ...humanMoveRef.current, dy: 0 };
        break;
      case 'a':
      case 'd':
        humanMoveRef.current = { ...humanMoveRef.current, dx: 0 };
        break;
      case 'm':
        humanMoveRef.current = { ...humanMoveRef.current, shoot: false };
        break;
      default:
        break;
    }
    
    // Submit human move when key is released
    if (['w', 'a', 's', 'd', 'm'].includes(event.key.toLowerCase())) {
      const humanPlayer = gameState.players.find(p => p.type === PLAYER_TYPES.HUMAN);
      if (humanPlayer) {
        setPendingMoves(prev => ({
          ...prev,
          [humanPlayer.id]: {
            move: { x: humanMoveRef.current.dx, y: humanMoveRef.current.dy },
            shoot: humanMoveRef.current.shoot ? [humanMoveRef.current.lastDirection.dx, humanMoveRef.current.lastDirection.dy] : null
          }
        }));
      }
    }
  }, [isGameStarted, gameState]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  const requestNextMoves = useCallback(() => {
    if (!gameState) return;

    const newPendingMoves = {};
    gameState.players.forEach(player => {
      if (player.type === PLAYER_TYPES.HUMAN) {
        // Human moves will be set by key presses
        newPendingMoves[player.id] = null;
      } else if (player.type === PLAYER_TYPES.RANDOM) {
        newPendingMoves[player.id] = getRandomPlayerMove(player, gameState);
      } else if (player.type === PLAYER_TYPES.LLM) {
        if (!llmPlayers.current[player.id]) {
          llmPlayers.current[player.id] = new LLMPlayer(player.id, player.team);
        }
        // Start the async process to get the LLM move
        llmPlayers.current[player.id].getNextMove(gameState, player.health, player.magazine)
          .then(move => {
            setPendingMoves(prev => ({ ...prev, [player.id]: move }));
          });
      }
    });
    setPendingMoves(newPendingMoves);
  }, [gameState]);

  useEffect(() => {
    if (isGameStarted && gameState && Object.values(pendingMoves).every(move => move !== null)) {
      // All moves are ready, update the game state
      setGameState(prevState => updateGameState(prevState, pendingMoves));
      // Request next moves
      requestNextMoves();
    }
  }, [isGameStarted, gameState, pendingMoves, requestNextMoves]);

  const updateGameState = (prevState, moves) => {
    const newState = { ...prevState, turn: prevState.turn + 1 };

    newState.shots = prevState.shots
      .map((shot) => {
        let newX = shot.x;
        let newY = shot.y;
        let blocked = false;
        for (let i = 0; i < BULLET_SPEED; i++) {
          newX += shot.dx;
          newY += shot.dy;
          if (isObstacle(newX, newY, newState.obstacles)) {
            blocked = true;
            break;
          }
        }
        if (blocked) {
          return null;
        }
        return { ...shot, x: newX, y: newY };
      })
      .filter((shot) => shot && shot.x >= 0 && shot.x < GRID_SIZE && shot.y >= 0 && shot.y < GRID_SIZE);

    newState.players = prevState.players.map(player => {
      const move = moves[player.id];
      if (!move) return player;

      let newX = player.x + move.move.x;
      let newY = player.y + move.move.y;

      if (
        newX >= 0 && newX < GRID_SIZE && 
        newY >= 0 && newY < GRID_SIZE && 
        !isObstacle(Math.round(newX), Math.round(newY), newState.obstacles)
      ) {
        player.x = newX;
        player.y = newY;
      }

      if (move.shoot && player.magazine > 0) {
        newState.shots.push({
          x: Math.round(player.x),
          y: Math.round(player.y),
          dx: move.shoot[0],
          dy: move.shoot[1],
          team: player.team,
        });
        player.magazine--;
      }

      if (player.magazine < MAX_MAGAZINE) {
        player.regenCounter++;
        if (player.regenCounter >= SHOT_REGEN_RATE) {
          player.magazine++;
          player.regenCounter = 0;
        }
      } else {
        player.regenCounter = 0;
      }

      return player;
    });

    newState.players = newState.players.map((player) => {
      const hit = newState.shots.some((shot) => {
        for (let i = 0; i <= BULLET_SPEED; i++) {
          const bulletX = shot.x - shot.dx * i;
          const bulletY = shot.y - shot.dy * i;
          if (Math.round(bulletX) === Math.round(player.x) && Math.round(bulletY) === Math.round(player.y) && shot.team !== player.team) {
            return true;
          }
        }
        return false;
      });
      return hit ? { ...player, health: player.health - 1 } : player;
    });

    newState.players = newState.players.filter((player) => player.health > 0);

    return newState;
  };

  const changePlayerType = (playerId, newType) => {
    if (!gameState) return;
    setGameState(prevState => ({
      ...prevState,
      players: prevState.players.map(player =>
        player.id === playerId ? { ...player, type: newType } : player
      )
    }));
  };

  const renderGrid = () => {
    if (!gameState) return null;
    const grid = [];
    for (let y = 0; y <= GRID_SIZE; y++) {
      for (let x = 0; x <= GRID_SIZE; x++) {
        const player = gameState.players.find((p) => Math.round(p.x) === x && Math.round(p.y) === y);
        const shot = gameState.shots.find((s) => {
          for (let i = 0; i <= BULLET_SPEED; i++) {
            const bulletX = s.x + s.dx * i;
            const bulletY = s.y + s.dy * i;
            if (Math.round(bulletX) === x && Math.round(bulletY) === y && !isObstacle(x, y, gameState.obstacles)) {
              return true;
            }
          }
          return false;
        });
        const obstacle = isObstacle(x, y, gameState.obstacles);
        const color = obstacle
          ? OBSTACLE_COLOR
          : player
          ? TEAM_COLORS[player.team]
          : shot
          ? SHOT_COLOR
          : 'black';
        grid.push(
          <div
            key={`${x}-${y}`}
            style={{
              width: '10px',
              height: '10px',
              backgroundColor: color,
              position: 'absolute',
              left: `${x * 10}px`,
              top: `${y * 10}px`,
            }}
          />
        );
      }
    }
    return grid;
  };

  const renderLifeCounters = () => {
    if (!gameState) return null;
    return gameState.players.map((player) => (
      <div key={player.id} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
        <div
          style={{
            width: '10px',
            height: '10px',
            backgroundColor: TEAM_COLORS[player.team],
            marginRight: '5px',
          }}
        />
        <div>
          Player {player.id + 1} ({player.type}): {Array(player.health).fill('❤️').join('')} | 
          Ammo: {player.magazine}/{MAX_MAGAZINE}
        </div>
      </div>
    ));
  };

  const renderPlayerTypeSelectors = () => {
    if (!gameState) return null;
    return gameState.players.map((player) => (
      <div key={player.id}>
        <label>
          Player {player.id + 1} Type:
          <select
            value={player.type}
            onChange={(e) => changePlayerType(player.id, e.target.value)}
          >
            <option value={PLAYER_TYPES.HUMAN}>Human</option>
            <option value={PLAYER_TYPES.RANDOM}>Random</option>
            <option value={PLAYER_TYPES.LLM}>LLM</option>
          </select>
        </label>
      </div>
    ));
  };

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      {!isGameStarted ? (
        <button onClick={startGame}>Start Game</button>
      ) : (
        <>
          <div
            style={{
              position: 'relative',
              width: `${GRID_SIZE * 10}px`,
              height: `${GRID_SIZE * 10}px`,
              margin: '0 auto',
            }}
          >
            {renderGrid()}
          </div>
          <div style={{ marginTop: '10px' }}>Turn: {gameState.turn}</div>
          <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
            {renderLifeCounters()}
          </div>
          <div style={{ marginTop: '10px' }}>
            Controls: WASD to move, M to shoot in the direction of your last move
          </div>
          <div style={{ marginTop: '10px' }}>
            {renderPlayerTypeSelectors()}
          </div>
        </>
      )}
    </div>
  );
};

export default Game;