import React, { useState, useEffect } from
'react';
import {
  Container,
  Typography,
  Box,
  styled,
  useTheme,
  Button
} from '@mui/material';

const StyledContainer =
styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const TitleContainer = styled(Box)({
  display: 'flex',
  alignItems: 'baseline',
});

const LargeDot = styled(Typography)(({
theme }) => ({
  fontSize: '5rem',
  lineHeight: 0,
  color: theme.palette.primary.main,
}));

const TetrisGame = () => {
  const theme = useTheme();
  const [gameOver, setGameOver] =
useState(false);
  const [score, setScore] = useState(0);
  const [grid, setGrid] = useState([]);
  const [currentShape, setCurrentShape] =
useState({
    type: 'I',
    x: Math.floor(Math.random() * 10),
    y: -1,
    width: 5,
    height: 1,
    color: '#33CC33'
  });
  const [nextShape, setNextShape] =
useState({
    type: 'O',
    x: Math.floor(Math.random() * 10),
    y: 0,
    width: 2,
    height: 2,
    color: '#66CCCC'
  });

  useEffect(() => {
    if (!gameOver) {
      const newGrid = grid.map((row, x) =>
{
        return row.map((cell, y) => {
          if (currentShape.y +
currentShape.height > y && currentShape.x
< x && x < currentShape.x +
currentShape.width) {
            return currentShape.color;
          }
          return cell;
        });
      });

      setGrid(newGrid);
    }

    const timer = setTimeout(() => {
      const newShape = { ...currentShape
};
      newShape.y++;
      setCurrentShape(newShape);

      if (newShape.y + newShape.height >
grid.length) {
        setGameOver(true);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [grid, currentShape]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const newGrid = grid.map((row, x) =>
{
        return row.map((cell, y) => {
          if (currentShape.y +
currentShape.height > y && currentShape.x
< x && x < currentShape.x +
currentShape.width) {
            return currentShape.color;
          }
          return cell;
        });
      });

      setGrid(newGrid);

      const linesCleared =
newGrid.reduce((total, row) => {
        if (row.every(cell => cell !==
'')) {
          total++;
        }
        return total;
      }, 0);

      setScore(score + linesCleared);
    }, 1000);

    return () => clearTimeout(timer);
  }, [grid]);

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowLeft':
        if (currentShape.x > 0 &&
!isCollision()) {
          setCurrentShape((prevShape) =>
({ ...prevShape, x: prevShape.x - 1 }));
        }
        break;
      case 'ArrowRight':
        if (currentShape.x +
currentShape.width < grid[0].length &&
!isCollision()) {
          setCurrentShape((prevShape) =>
({ ...prevShape, x: prevShape.x + 1 }));
        }
        break;
      case 'ArrowDown':
        if (!isCollision()) {
          setCurrentShape((prevShape) =>
({ ...prevShape, y: prevShape.y + 1 }));
        }
        break;
      case 's':
        setGameOver(true);
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown',
handleKeyDown);

    return () => {

document.removeEventListener('keydown',
handleKeyDown);
    };
  }, []);

  const isCollision = () => {
    for (let y = 0; y <
currentShape.height; y++) {
      for (let x = currentShape.x; x <
currentShape.x + currentShape.width; x++)
{
        if (currentShape.y + y >=
grid.length || grid[currentShape.y + y][x]
!== '') {
          return true;
        }
      }
    }
    return false;
  };

  const handleStartGame = () => {
    setGameOver(false);
    setScore(0);
    setCurrentShape({
      type: 'I',
      x: Math.floor(Math.random() * 10),
      y: -1,
      width: 5,
      height: 1,
      color: '#33CC33'
    });
  };

  return (
    <StyledContainer maxWidth="md">
      {gameOver ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <Typography variant="h4"
gutterBottom>
            Game Over! Your score is
{score}.
          </Typography>
          <LargeDot>.</LargeDot>
          <Button
            variant="contained"
            onClick={handleStartGame}
          >
            Play Again!
          </Button>
        </Box>
      ) : (
        <div>
          <Typography variant="h4"
gutterBottom>
            Tetris Game
          </Typography>
          <LargeDot>.</LargeDot>
          <Typography variant="body1">
            Controls: Arrow Left, Arrow
Right, Arrow Down, S to start over.
          </Typography>

          <div style={{ display: 'flex',
justifyContent: 'center' }}>
            {grid.map((row, x) => (
              <div key={x} style={{
display: 'flex', justifyContent: 'center'
}}>
                {row.map((cell, y) => (
                  <div
                    key={y}
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor:
cell === '' ? '#000' : cell,
                      margin: 2,
                    }}
                  />
                ))}
              </div>
            ))}
          </div>

          <Typography variant="body1">
            Score: {score}
          </Typography>
        </div>
      )}
    </StyledContainer>
  );
};

export default TetrisGame;