import React from 'react';
import WrittenList from '../components/WrittenList';
import writtenData from '../data/written.json';
import { useDocumentTitle } from '../hooks/useDocumentTitle';

const Written = () => {
  useDocumentTitle('Written');
  return <WrittenList title="Things I have written" data={writtenData} />;
};

export default Written;
