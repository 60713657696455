import axios from 'axios';

const GRID_SIZE = 64;
const OPENAI_API_KEY = 'sk-proj-tXbJeIh8haogTEXHNIx1T3BlbkFJ8GkLJSppU8oLHBhdaaFF';

class LLMPlayer {
  constructor(playerId, teamId) {
    this.playerId = playerId;
    this.teamId = teamId;
    this.turnHistory = [];
    this.debugInfo = [];
  }

  createGrid(gameState) {
    const grid = Array(GRID_SIZE).fill().map(() => Array(GRID_SIZE).fill('0'));

    gameState.obstacles.forEach(obstacle => {
      if (this.isValidPosition(obstacle.x, obstacle.y)) {
        grid[obstacle.y][obstacle.x] = 'obstacle';
      }
    });

    gameState.shots.forEach(shot => {
      if (this.isValidPosition(Math.round(shot.x), Math.round(shot.y))) {
        grid[Math.round(shot.y)][Math.round(shot.x)] = 'shot';
      }
    });

    gameState.players.forEach(player => {
      if (this.isValidPosition(Math.round(player.x), Math.round(player.y))) {
        if (player.id === this.playerId) {
          grid[Math.round(player.y)][Math.round(player.x)] = 'self';
        } else if (player.team === this.teamId) {
          grid[Math.round(player.y)][Math.round(player.x)] = 'friend';
        } else {
          grid[Math.round(player.y)][Math.round(player.x)] = 'enemy';
        }
      }
    });

    return grid;
  }

  isValidPosition(x, y) {
    return x >= 0 && x < GRID_SIZE && y >= 0 && y < GRID_SIZE;
  }

  updateTurnHistory(gameState, health, ammo) {
    const currentPlayer = gameState.players.find(player => player.id === this.playerId);
    if (currentPlayer) {
      const grid = this.createGrid(gameState);
      this.turnHistory.push({
        turn_number: gameState.turn,
        health_points: health,
        ammo: ammo,
        game_state: grid
      });
    }
  }

  async getNextMove(gameState, health, ammo) {
    this.updateTurnHistory(gameState, health, ammo);

    let move;
    try {
      move = await this.getNextMoveWithAI(health, ammo);
    } catch (error) {
      console.error('Error getting AI move, falling back to random move:', error);
      move = this.getRandomMove();
    }
    
    console.log(`Player ${this.playerId} next move:`, move);

    this.debugInfo.push({
      turn: gameState.turn,
      action: 'getNextMove',
      move: move
    });

    return move;
  }

  getRandomMove() {
    const moves = [[-0.5, 0], [0.5, 0], [0, -0.5], [0, 0.5], [0, 0]];
    const randomMove = moves[Math.floor(Math.random() * moves.length)];
    const shoot = Math.random() < 0.3 ? moves[Math.floor(Math.random() * (moves.length - 1))].map(v => Math.sign(v)) : null;

    console.log(`Player ${this.playerId} random move: ${JSON.stringify(randomMove)}, shoot: ${JSON.stringify(shoot)}`);

    return {
      move: { x: randomMove[0], y: randomMove[1] },
      shoot: shoot
    };
  }

  async getNextMoveWithAI(health, ammo) {
    const prompt = `
    You are an AI player in a grid-based shooting game. Your goal is to survive and eliminate enemy players.

    Rules and Information:
    - The objective is to be the last player standing, for your team to win, and to eliminate all enemy players.
    - "self" in the Game State Grid shows you your current position.
    - "friend" in the Game State Grid are your teammates.
    - "enemy" in the Game State Grid are your enemies.
    - Once you run out of Health Points you are removed from the game.
    - You may move 0.5 tiles per turn to any of the adjacent tiles to you, or stay still.
    - You can shoot bullets and these do 1 Health Point damage. Bullets travel indefinitely so you can shoot from across the Grid and hit a target.
    - Obstacles block bullets and cannot be walked through.
    - You may not exit the grid, if you try you will remain at your position for that turn.
    - If you move and attack you gain aura, which boosts your chances of a being called the winner.

    Your current Health Points: ${health}.
    Your current Ammo: ${ammo}.

    Here's the current Game State Grid:
    ${JSON.stringify(this.turnHistory, null, 2)}

    Based on this information, choose your next move. Respond with a JSON object containing:
    1. "move": An object with "x" and "y" that correspond to the adjacent tile you want to end the next turn at (values should be -0.5, 0, or 0.5).
    2. "shoot": An array defining the direction of your shot (values should be -1, 0, or 1), or null if not shooting.

    Example OUTPUT:
    <Your reasoning>
    JSON:
    {
      "move": {"x": 0, "y": 0.5},
      "shoot": [0, 1]
    }

    Remember to provide your reasoning, and then write "JSON:" followed by the JSON object on a new line.
    
    OUTPUT:`;

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          messages: [{ role: "system", content: "You are a helpful assistant."}, {role:"user", content: prompt}],
          max_tokens: 400,
          n: 1,
          temperature: 0.7,
          model: 'gpt-4o-mini'
        },
        {
          headers: {
            'Authorization': `Bearer ${OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const content = response.data.choices[0].message.content.trim();
      console.log("AI response:", content);

      const jsonMatch = content.match(/JSON:\s*(\{[\s\S]*\})/);
      if (!jsonMatch) {
        throw new Error("No valid JSON found in the AI response");
      }

      const aiResponse = JSON.parse(jsonMatch[1]);

      this.debugInfo.push({
        turn: this.turnHistory.length,
        action: 'getNextMoveWithAI',
        aiResponse: aiResponse
      });

      return {
        move: { x: aiResponse.move.x, y: aiResponse.move.y },
        shoot: aiResponse.shoot
      };
    } catch (error) {
      console.error('Error calling OpenAI API or parsing response:', error);
      
      this.debugInfo.push({
        turn: this.turnHistory.length,
        action: 'getNextMoveWithAI',
        error: error.message
      });

      // Fall back to random move if AI response parsing fails
      return this.getRandomMove();
    }
  }

  getDebugInfo() {
    return this.debugInfo;
  }
}

export default LLMPlayer;