import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './pages/Home';
import Written from './pages/Written';
import Experienced from './pages/Experienced';
import Thought from './pages/Thought';
import Layout from './components/Layout';
import Playground from './pages/Playground';
import ChatbotApp from './components/ChatbotApp';
import FightClub from './components/FightClub';
import MovieGenerator from './components/MovieGenerator';
import TetrisGame from './components/Tetris';
import BlogPost from './components/BlogPost';
import writtenData from './data/written.json';
import CustomScrollbar from './components/CustomScrollbar';
import { Analytics } from "@vercel/analytics/react";

// Import Libre Franklin font
import '@fontsource/libre-franklin/300.css';
import '@fontsource/libre-franklin/400.css';
import '@fontsource/libre-franklin/500.css';
import '@fontsource/libre-franklin/700.css';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    background: {
      default: '#9ad4f5',
    },
  },
  typography: {
    fontFamily: [
      'Libre Franklin',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    // Add specific typography styles for blog posts
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
      marginBottom: '1rem',
    },
    body1: {
      fontSize: '1.1rem',
      lineHeight: 1.7,
    },
  },
  components: {
    // Add custom styles for the blog post card
    MuiCard: {
      styleOverrides: {
        root: {
          transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          },
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CustomScrollbar />
      <CssBaseline />
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/written" element={<Written />} />
            {/* Add new route for individual blog posts */}
            <Route path="/written/:id" element={<BlogPost posts={writtenData} />} />
            <Route path="/experienced" element={<Experienced />} />
            <Route path="/thought" element={<Thought />} />
            <Route path="/playground" element={<Playground />} />
            <Route path="/playground/hendy" element={<ChatbotApp />} />
            <Route path="/playground/fightclub" element={<FightClub />} />
            <Route path="/playground/ascii-movie-generator" element={<MovieGenerator />} />
            <Route path="/playground/tetris" element={<TetrisGame />} />
          </Routes>
        </Layout>
      </Router>
      <Analytics/>
    </ThemeProvider>
  );
}

export default App;