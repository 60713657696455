import React from 'react';
import { Typography, Container, Box, IconButton } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import SchoolIcon from '@mui/icons-material/School';
import GitHubIcon from '@mui/icons-material/GitHub';

const Home = () => {
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 'calc(100vh - 100px)', // Subtract AppBar height
          py: 4,
          position: 'relative', // Add this to position the note absolutely within this container
        }}
      >
        <Typography variant="h4" gutterBottom>
          Juan J Vazquez
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          juanv.research at gmail.com
        </Typography>
        <Box sx={{ mt: 1 }}>
          <a href="https://x.com/Juan_VaGu" target="_blank" rel="noopener noreferrer">
            <IconButton aria-label="Twitter" color="primary">
              <TwitterIcon />
            </IconButton>
          </a>
          <a href="https://github.com/juanjvazquez" target="_blank" rel="noopener noreferrer">
            <IconButton aria-label="GitHub" color="primary">
              <GitHubIcon />
            </IconButton>
          </a>
          <a href="https://scholar.google.com/citations?user=1jpQzAYAAAAJ&hl=en" target="_blank" rel="noopener noreferrer">
            <IconButton aria-label="arXiv" color="primary">
              <SchoolIcon />
            </IconButton>
          </a>
        </Box>
        
        {/* Add the "A work in progress" note */}
        <Typography 
          variant="caption" 
          sx={{
            position: 'absolute',
            bottom: 16,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'text.secondary',
            fontStyle: 'italic',
          }}
        >
          A work in progress.
        </Typography>
      </Box>
    </Container>
  );
};

export default Home;